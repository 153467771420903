import { uuid } from 'vue-uuid'
import * as fb from '../../../firebase'

export default {
  namespaced: true,
  state: {
  },
  actions: {
    uploadNewImage(_, payload) {
      const uid = uuid.v1()
      const metadata = {
        contentType: payload.type,
      }
      return fb.storage.ref(`images/${uid}${payload.name}`).put(payload, metadata).then(uploadTaskSnapshot => uploadTaskSnapshot.ref.getDownloadURL())
    },
    uploadNewKeyFile(_, payload) {
      const uid = uuid.v1()
      const metadata = {
        contentType: payload.type,
      }
      return fb.storage.ref(`key_files/${uid}${payload.name}`).put(payload, metadata).then(uploadTaskSnapshot => uploadTaskSnapshot.ref.getDownloadURL())
    },
    downloadFile(_, payload) {
      const metadata = {
        contentType: payload.type,
      }
      return fb.storage.ref(`key_files/${payload.name}`).put(payload, metadata).then(uploadTaskSnapshot => uploadTaskSnapshot.ref.getDownloadURL())
    },
  },
  mutations: {
  },
  getters: {
    loadingStatus(state) {
      return {
        isLoading: state.isLoading,
      }
    },
  },
}
