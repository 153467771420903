/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import checkoutModule from './checkout/index'
// eslint-disable-next-line import/no-cycle
import authModule from './modules/auth/index'
import toastrModule from './modules/toastr-messages/index'
import commonModule from './modules/common'
import appsitesModule from './modules/appsites'
import purchasesModule from './modules/purchases/index'
import loadingModule from './modules/loading'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    checkout: checkoutModule,
    appSite: appsitesModule,
    toaster: toastrModule,
    auth: authModule,
    loading: loadingModule,
    common: commonModule,
    purchases: purchasesModule,
  },
  mutations: {},
  strict: process.env.DEV,
})
