import firebase from 'firebase'
import firebaseApp from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyCJxE0TaU2GuvMh9hXXcrWxmFvcgCBbAgs',
  authDomain: 'wpandroidapp.firebaseapp.com',
  databaseURL: 'https://wpandroidapp.firebaseio.com',
  projectId: 'wpandroidapp',
  storageBucket: 'wpandroidapp.appspot.com',
  messagingSenderId: '837470437600',
  appId: '1:837470437600:web:222528311cd828bb69d085',
}
firebaseApp.initializeApp(firebaseConfig)

// utils
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()

// collection references
const usersCollection = db.collection('Users')
const buildsCollection = db.collection('Build')
const buildsQueueCollection = db.collection('BuildQueue')
const ordersCollection = db.collection('Orders')
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  buildsCollection,
  buildsQueueCollection,
  ordersCollection,
  storage,
  firebase,
}
