export default {
  setUser(state, payload) {
    state.email = payload.email
    state.userId = payload.userId
    state.userName = payload.userName
    state.remainingBasicBuilds = payload.remainingBasicBuilds
    state.remainingPremiumBuilds = payload.remainingPremiumBuilds
    state.remainingStandardBuilds = payload.remainingStandardBuilds
    state.usedBasicBuilds = payload.usedBasicBuilds
    state.usedPremiumBuilds = payload.usedPremiumBuilds
    state.basicSubscriptionEndDate = payload.basicSubscriptionEndDate
    state.standardSubscriptionEndDate = payload.standardSubscriptionEndDate
    state.premiumSubscriptionEndDate = payload.premiumSubscriptionEndDate
  },
  setAuthentication(state, payload) {
    state.isAuthenticated = payload
  },
  removeUser(state) {
    state.email = null
    state.userId = null
    state.userName = null
    state.remainingBasicBuilds = null
    state.remainingPremiumBuilds = null
    state.remainingStandardBuilds = null
    state.usedBasicBuilds = null
    state.usedPremiumBuilds = null
    state.basicSubscriptionEndDate = null
    state.standardSubscriptionEndDate = null
    state.premiumSubscriptionEndDate = null
  },
}
