export default {
  namespaced: true,
  state: {
    title: null,
    icon: null,
    variant: null,
  },
  actions: {
    setMessage(context, payload) {
      context.commit('setMessage', payload)
    },
    clearMessage(context) {
      context.commit('clearMessage')
    },
  },
  mutations: {
    setMessage(state, payload) {
      state.title = payload.title
      state.icon = payload.icon
      state.variant = payload.variant
    },
    clearMessage(state) {
      state.title = null
      state.icon = null
      state.variant = null
    },
  },
  getters: {
    myMessage(state) {
      return {
        title: state.title,
        icon: state.icon,
        variant: state.variant,
      }
    },
  },
}
