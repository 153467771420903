import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('@/views/pages/pricing/Pricing.vue'),
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/pages/checkout/Checkout.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Pricing',
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/pages/authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forget_password',
      name: 'forget-passoword',
      component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact_us',
      name: 'contact',
      component: () => import('@/views/pages/contact-us/AppFAQ.vue'),
      meta: {
        pageTitle: 'Contact Us',
        breadcrumb: [
          {
            text: 'Contact Us',
            active: true,
          },
        ],
      },
    },
    {
      path: '/purchases',
      name: 'purchases',
      component: () => import('@/views/pages/purchases/Purchases.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Purchases',
        breadcrumb: [
          {
            text: 'Purchases',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        requiresAuth: true,
      },
    },
    {
      path: '/add-new-app',
      name: 'addNewApp',
      component: () => import('@/views/pages/add-new-app/AddNewApp.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add New App',
        breadcrumb: [
          {
            text: 'Add new App',
            active: true,
          },
        ],
      },
    },
    {
      path: '/view-app/:id',
      name: 'view-app',
      component: () => import('@/views/pages/view-selected-app/AppView.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'View App Details',
        breadcrumb: [
          {
            text: 'View App',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-app/:id',
      name: 'edit-app',
      component: () => import('@/views/pages/edit-selected-app/EditAppDetails.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit App Details',
        breadcrumb: [
          {
            text: 'View App',
            to: { name: 'view-app' },
          },
          {
            text: 'Edit App',
            active: true,
          },
        ],
      },
    },
    {
      path: '/build-app/:id',
      name: 'build-app',
      component: () => import('@/views/pages/build-app/AppBuildApp.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Build App',
        breadcrumb: [
          {
            text: 'View App',
            to: { name: 'view-app' },
          },
          {
            text: 'Build App',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.name !== 'Login' && to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    next('/login')
  } else {
    next()
  }

  // if (to.meta.requiresAuth && store.getters['auth/isAuthenticated']) {
  //   return next()
  // }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
