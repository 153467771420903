export default {
  setSelectedApp(state, payload) {
    state.selectedApp = payload
  },
  setUsersApps(state, payload) {
    state.userApps = payload
  },
  setSelectedAppLogs(state, payolad) {
    state.selectedAppLogs = payolad
  },
  removeUserApps(state) {
    state.selectedAppLogs = []
  },
}
