/* eslint-disable no-unused-vars */
import { uuid } from 'vue-uuid'
import { getAllowedFeatureValue } from '@/services/feature.service'
import firebase from 'firebase'
import * as fb from '../../../firebase'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import router from '../../../router/index'

export default {
  addNewApp({ dispatch }, payload) {
    dispatch('loading/startLoading', null, { root: true })
    dispatch('writeNewApptoDb', payload)
  },
  async writeNewApptoDb({
    dispatch, __, ___, rootGetters,
  }, payload) {
    const siteRef = fb.usersCollection.doc(rootGetters['auth/userId']).collection('sites').doc()
    const newDocRef = await siteRef.get()
    fb.usersCollection.doc(rootGetters['auth/userId']).collection('sites').doc(newDocRef.id).set({
      siteName: payload.appName,
      siteUrl: payload.websiteUrl,
      appLogoURL: payload.appLogo ? payload.appLogo : '',
      // Colors
      accentColor: payload.accentColor,
      primaryColor: payload.primaryColor,
      websiteColor: payload.websiteColor,
      primaryDarkColor: payload.primaryDarkColor,
      introBackgroundColor: '#FFFFFF',
      introTitleColor: '#686868',
      introBodyColor: '#686868',
      introDotDarkColor: '#A291C5',
      introDotLightColor: '#DBD6EA',
      splashColor: '#FFFFFF',
      //
      status: 'draft',
      siteId: newDocRef.id,
      enableCenterLoading: false,
      enableTopLoading: true,
      enablePDFView: true,
      enablePhoneDialer: true,
      enableWhatsAppShare: true,
      fullAddDelaySec: 60,
      removeFooter: true,
      showIntro: false,
      introScreens: [],
      splashDisplayLength: 3000,
      fullScreenSplash: false,
      fullScreenApp: false,
      enableAdds: false,
      bottomAdds: false,
      fullAdds: false,
      topAdds: false,
      enableFileDownload: true,
      enableFileUpload: true,
      enableCache: true,
      openExternalLinksInApp: false,
      enableDeepLinking: false,
      enableNotification: false,
      preserveCookies: false,
      oneSignalAppId: '',
      splashScreen: '',
      admob_app_id: 'ca-app-pub-3940256099942544~3347511713', // Values given and valid value required
      admob_banner_id: '',
      admob_fullad_id: '',
      start: 'LET`S GO',
      next: 'NEXT',
      skip: 'SKIP',
      urlScheme: 'https',
      createdAt: new Date(),
    })
      .then(response => {
        dispatch('loading/stopLoading', __, { root: true })
        setTimeout(() => {
          dispatch('toaster/setMessage', {
            title: 'New App Added Successfully',
            icon: 'EditIcon',
            variant: 'success',
          }, { root: true })
        }, 200)
        router.push(`view-app/${newDocRef.id}`)
      })
  },
  fetchSelectedApp(ctx, payload) {
    const appRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(payload.id)
    return appRef.onSnapshot(doc => {
      if (doc.exists) {
        ctx.commit('setSelectedApp', doc.data())
      }
    })
  },
  async createDuplicateApp(ctx, payload) {
    const appRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(payload.id)
    return appRef.onSnapshot(doc => {
      if (doc.exists) {
        console.log(doc.data())
        const obj = doc.data()
        const clonedObj = (({ build_download_url, ...o }) => o)(obj)
        const siteRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc()
        const newDocRef = siteRef.get().then(item => {
          console.log(item.id)
          fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(item.id).set({
            ...clonedObj,
            siteId: item.id,
            status: 'draft',
            createdAt: new Date(),
          })
            .then(response => {
              // ctx.dispatch('loading/stopLoading', __, { root: true })

              ctx.dispatch('fetchAllusersApps')
              setTimeout(() => {
                ctx.dispatch('toaster/setMessage', {
                  title: 'New App Added Successfully',
                  icon: 'EditIcon',
                  variant: 'success',
                }, { root: true })
              }, 200)

              // router.push(`view-app/${newDocRef.id}`)
            })
        })
      }
    })
  },
  updateSelectedApp(ctx, payload) {
    ctx.dispatch('loading/startLoading', null, { root: true })
    fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(payload.id).update({
      siteName: payload.appData.siteName,
      siteUrl: payload.appData.siteUrl,
      appLogoURL: payload.appData.appLogoURL,
      // Colors
      accentColor: payload.appData.accentColor,
      primaryColor: payload.appData.primaryColor,
      websiteColor: payload.appData.websiteColor,
      primaryDarkColor: payload.appData.primaryDarkColor,
      introBackgroundColor: payload.appData.introBackgroundColor,
      introTitleColor: payload.appData.introTitleColor,
      introBodyColor: payload.appData.introBodyColor,
      introDotDarkColor: payload.appData.introDotDarkColor,
      introDotLightColor: payload.appData.introDotLightColor,
      splashColor: payload.appData.splashColor,
      //
      status: payload.appData.status,
      siteId: payload.appData.siteId,
      enableCenterLoading: payload.appData.enableCenterLoading,
      enableTopLoading: payload.appData.enableTopLoading,
      enablePDFView: payload.appData.enablePDFView,
      enablePhoneDialer: payload.appData.enablePhoneDialer,
      enableWhatsAppShare: payload.appData.enableWhatsAppShare,
      fullAddDelaySec: payload.appData.fullAddDelaySec,
      removeFooter: payload.appData.removeFooter,
      showIntro: payload.appData.showIntro,
      introScreens: payload.appData.introScreens,
      splashDisplayLength: payload.appData.splashDisplayLength,
      fullScreenSplash: payload.appData.fullScreenSplash,
      fullScreenApp: payload.appData.fullScreenApp,
      enableAdds: payload.appData.enableAdds,
      bottomAdds: payload.appData.bottomAdds,
      fullAdds: payload.appData.fullAdds,
      topAdds: payload.appData.topAdds,
      enableFileDownload: payload.appData.enableFileDownload,
      enableFileUpload: payload.appData.enableFileUpload,
      enableCache: payload.appData.enableCache,
      openExternalLinksInApp: payload.appData.openExternalLinksInApp,
      enableDeepLinking: payload.appData.enableDeepLinking,
      enableNotification: payload.appData.enableNotification,
      oneSignalAppId: payload.appData.oneSignalAppId,
      splashScreen: payload.appData.splashScreen,
      admob_app_id: payload.appData.admob_app_id, // Values given
      admob_banner_id: payload.appData.admob_banner_id, // Values given
      admob_fullad_id: payload.appData.admob_fullad_id, // Values given
      start: payload.appData.start,
      next: payload.appData.next,
      skip: payload.appData.skip,
      preserveCookies: payload?.appData?.preserveCookies,
      urlScheme: payload.appData.urlScheme,
    })
      .then(response => {
        ctx.dispatch('loading/stopLoading', null, { root: true })
        setTimeout(() => {
          ctx.dispatch('toaster/setMessage', {
            title: 'App Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
          }, { root: true })
        }, 200)
        router.push(`/view-app/${payload.id}`)
      })
  },
  fetchAllusersApps(ctx) {
    const sitesRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites')
    return sitesRef.orderBy('createdAt').get().then(querySnapshot => {
      const usersApps = []
      querySnapshot.forEach(doc => {
        const app = {
          accentColor: doc.data().accentColor,
          appLogoUrl: doc.data()?.appLogoURL,
          primaryColor: doc.data().primaryColor,
          siteId: doc.data().siteId,
          siteUrl: doc.data().siteUrl,
          siteName: doc.data().siteName,
          status: doc.data().status,
          build_download_url: doc.data().build_download_url,
        }

        usersApps.unshift(app)
      })
      ctx.commit('setUsersApps', usersApps)
    })
  },
  async startBuild(ctx, payload) {
    try {
      const rawAppData = ctx.rootGetters['appSite/selectedApp']
      const url = new URL(rawAppData.siteUrl)
      const baseUrl = url.hostname.replace('http://', '').replace('https://', '').replace('/', '')
      const siteRef = fb.buildsCollection.doc()
      const newDocRef = await siteRef.get()
      const app = {
        appId: rawAppData.id,
        buildId: newDocRef.id,
        config: {
          ENABLE_CENTER_LOADING: getAllowedFeatureValue('centerLoading', rawAppData.enableCenterLoading, payload.buildType),
          ENABLE_TOP_LOADING: getAllowedFeatureValue('topLoading', rawAppData.enableTopLoading, payload.buildType),
          ENABLE_PDF_VIEW: getAllowedFeatureValue('pdfView', rawAppData.enablePDFView, payload.buildType),
          ENABLE_PHONE_DIALER: getAllowedFeatureValue('phoneDialer', rawAppData.enablePhoneDialer, payload.buildType),
          ENABLE_WHATSAPP_SHARE: getAllowedFeatureValue('whatsappShare', rawAppData.enableWhatsAppShare, payload.buildType),
          FULL_AD_DELAY_SEC: rawAppData.fullAddDelaySec,
          REMOVE_FOOTER: getAllowedFeatureValue('removeFooter', rawAppData.removeFooter, payload.buildType),
          SHOW_INTRO: getAllowedFeatureValue('showIntro', rawAppData.showIntro, payload.buildType),
          INTRO_SCREEN_COUNT: rawAppData.introScreens?.length ?? 0,
          SPLASH_DISPLAY_LENGTH: rawAppData.splashDisplayLength,
          FULLSCREEN_SPLASH: getAllowedFeatureValue('fullScreenSplash', rawAppData.fullScreenSplash, payload.buildType),
          FULLSCREEN_APP: getAllowedFeatureValue('fullScreenApp', rawAppData.fullScreenApp, payload.buildType),
          ENABLE_ADS: getAllowedFeatureValue('enableAdds', rawAppData.enableAdds, payload.buildType),
          BOTTOM_ADS: getAllowedFeatureValue('bottomAds', rawAppData.bottomAdds, payload.buildType),
          FULL_ADS: getAllowedFeatureValue('fullAds', rawAppData.fullAdds, payload.buildType),
          TOP_ADS: getAllowedFeatureValue('topAds', rawAppData.topAdds, payload.buildType),
          ENABLE_FILE_DOWNLOAD: getAllowedFeatureValue('fileDownload', rawAppData.enableFileDownload, payload.buildType),
          ENABLE_FILE_UPLOAD: getAllowedFeatureValue('fileUpload', rawAppData.enableFileUpload, payload.buildType),
          ENABLE_CACHE: getAllowedFeatureValue('enableCache', rawAppData.enableCache, payload.buildType),
          OPEN_EXTERNAL_LINKS_IN_APP: getAllowedFeatureValue('externalLinks', rawAppData.openExternalLinksInApp, payload.buildType),
          ENABLE_DEEP_LINKING: getAllowedFeatureValue('deepLinking', rawAppData.enableDeepLinking, payload.buildType),
          ENABLE_NOTIFICATION: getAllowedFeatureValue('enableNotifications', rawAppData.enableNotification, payload.buildType),
          ONESIGNAL_APP_ID: rawAppData.oneSignalAppId,
          PRESERVE_COOKIES: getAllowedFeatureValue('preserveCookies', rawAppData.preserveCookies, payload.buildType),
          SHOW_WATERMARK: getAllowedFeatureValue('showWatermark', true, payload.buildType),
        },
        gradle: {
          isCustomKey: payload.keyType === 'customKey',
          isNewKey: payload.keyType === 'newKey',
          key: {
            key_password: payload.keyData?.keyPassword ?? 'webtoapp2021',
            keystore_password: payload.keyData?.keyStorePassword ?? 'webtoapp2021',
            key_alias: payload.keyData?.keyAlias ?? 'key0',
            locality: payload.keyData?.locality ?? 'test',
            name: payload.keyData?.name ?? 'test',
            organization: payload.keyData?.organization ?? 'test',
            organization_unit: payload.keyData?.organizationUnit ?? 'test',
            state: payload.keyData?.state ?? 'test',
            country_code: payload.keyData?.countryCode ?? 'CC',
          },
          customKey: {
            key_password: payload.keyData?.keyPassword ?? 'webtoapp2021',
            keystore_password: payload.keyData?.keyStorePassword ?? 'webtoapp2021',
            key_alias: payload.keyData?.keyAlias ?? 'key0',
            download_url: payload.keyData?.fileUploadedUrl ?? '',
          },
          versionCode: payload.basicData?.versionCode,
          versionName: payload.basicData?.versionName,
          packageName: payload.basicData?.packageName,
        },
        res: {
          ico: rawAppData?.appLogoURL,
          splash: rawAppData?.splashScreen,
          intro1: rawAppData?.introScreens[0]?.image || '',
          intro2: rawAppData?.introScreens[1]?.image || '',
          intro3: rawAppData?.introScreens[2]?.image || '',
          intro4: rawAppData?.introScreens[3]?.image || '',
          intro5: rawAppData?.introScreens[4]?.image || '',
          intro6: rawAppData?.introScreens[5]?.image || '',
          intro7: rawAppData?.introScreens[6]?.image || '',
          intro8: rawAppData?.introScreens[7]?.image || '',
        },
        xml: {
          colors: {
            accent: rawAppData.accentColor,
            primary: rawAppData.primaryColor,
            primaryDark: rawAppData.primaryDarkColor,
            splash: rawAppData.splashColor,
            webViewBackground: rawAppData.websiteColor,
            introBackground: rawAppData.introBackgroundColor,
            introTitle: rawAppData.introTitleColor,
            introBody: rawAppData.introBodyColor,
            introDotDark: rawAppData.introDotDarkColor,
            introDotLight: rawAppData.introDotLightColor,
          },
          strings: {
            admob_app_id: rawAppData.admob_app_id,
            admob_banner_id: rawAppData.admob_banner_id,
            admob_fullad_id: rawAppData.admob_fullad_id,
            app_name: rawAppData.siteName,
            baseURL: baseUrl,
            start: rawAppData.start,
            next: rawAppData.next,
            skip: rawAppData.skip,
            slide_1_desc: rawAppData.introScreens[0]?.description || '',
            slide_2_desc: rawAppData.introScreens[1]?.description || '',
            slide_3_desc: rawAppData.introScreens[2]?.description || '',
            slide_4_desc: rawAppData.introScreens[3]?.description || '',
            slide_5_desc: rawAppData.introScreens[4]?.description || '',
            slide_6_desc: rawAppData.introScreens[5]?.description || '',
            slide_7_desc: rawAppData.introScreens[6]?.description || '',
            slide_8_desc: rawAppData.introScreens[7]?.description || '',
            slide_1_title: rawAppData.introScreens[0]?.title || '',
            slide_2_title: rawAppData.introScreens[1]?.title || '',
            slide_3_title: rawAppData.introScreens[2]?.title || '',
            slide_4_title: rawAppData.introScreens[3]?.title || '',
            slide_5_title: rawAppData.introScreens[4]?.title || '',
            slide_6_title: rawAppData.introScreens[5]?.title || '',
            slide_7_title: rawAppData.introScreens[6]?.title || '',
            slide_8_title: rawAppData.introScreens[7]?.title || '',
            siteURL: rawAppData.siteUrl,
            urlScheme: rawAppData.urlScheme,
          },
        },
      }
      const savingBuild = {
        id: newDocRef.id,
        site_id: rawAppData.siteId,
        status: 'Pending',
        user_id: ctx.rootGetters['auth/userId'],
        app_data: JSON.stringify(app),
        build_type: payload.buildType,
        date: new Date(),
      }
      ctx.dispatch('loading/startLoading', null, { root: true })
      fb.buildsCollection.doc(newDocRef.id).set({
        ...savingBuild,
      }).then(response => {
        // writeToBuildQueue
        const buildQueObj = {
          id: newDocRef.id,
          time: new Date(),
          json: JSON.stringify(app),
        }
        fb.buildsQueueCollection.doc().set({
          ...buildQueObj,
        }).then(() => {})
        // Create Logs
        fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(rawAppData.siteId).collection('logs')
          .doc()
          .set({
            time: new Date(),
            title: 'In Build Queue',
            message: 'App was put to Build Queue',
            buildId: newDocRef.id,
            variant: 'info',
          })
          .then(() => {
            ctx.dispatch('fetchSelectedAppLogs', { id: rawAppData.siteId })
          })
          // Update Status to Pending
        fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(rawAppData.siteId)
          .set({
            status: 'Pending',
          }, { merge: true })
          .then(() => {
            ctx.dispatch('loading/stopLoading', null, { root: true })
            ctx.dispatch('fetchSelectedApp', { id: rawAppData.siteId })
            router.push({ name: 'view-app', params: { id: rawAppData.siteId } })
          })
        const user = fb.usersCollection.doc(ctx.rootGetters['auth/userId'])
        const basicSubscriptionEnd = ctx.rootGetters['auth/user'].basicSubscriptionEndDate ? new Date(ctx.rootGetters['auth/user'].basicSubscriptionEndDate.toDate()) : new Date()
        const standardSubscriptionEnd = ctx.rootGetters['auth/user'].standardSubscriptionEndDate ? new Date(ctx.rootGetters['auth/user'].standardSubscriptionEndDate.toDate()) : new Date()
        const premiumSubscriptionEnd = ctx.rootGetters['auth/user'].premiumSubscriptionEndDate ? new Date(ctx.rootGetters['auth/user'].premiumSubscriptionEndDate.toDate()) : new Date()
        // Update remaining builds
        if (payload.buildType === 'basic') {
          if (basicSubscriptionEnd <= new Date()) {
            user.set({
              remainingBasicBuilds: firebase.firestore.FieldValue.increment(-1),
            }, { merge: true }).then(() => {
              ctx.dispatch('auth/fetchUserProfileWithoutNav', ctx.rootGetters['auth/userId'], { root: true })
            })
          }
        }
        if (payload.buildType === 'premium') {
          if (standardSubscriptionEnd <= new Date()) {
            user.set({
              remainingPremiumBuilds: firebase.firestore.FieldValue.increment(-1),
            }, { merge: true }).then(() => {
              ctx.dispatch('auth/fetchUserProfileWithoutNav', ctx.rootGetters['auth/userId'], { root: true })
            })
          }
        }
        if (payload.buildType === 'standard') {
          if (premiumSubscriptionEnd <= new Date()) {
            user.set({
              remainingStandardBuilds: firebase.firestore.FieldValue.increment(-1),
            }, { merge: true }).then(() => {
              ctx.dispatch('auth/fetchUserProfileWithoutNav', ctx.rootGetters['auth/userId'], { root: true })
            })
          }
        }
        setTimeout(() => {
          ctx.dispatch('toaster/setMessage', {
            title: 'App Was Put to build Successfully',
            icon: 'EditIcon',
            variant: 'success',
          }, { root: true })
        }, 100)
      })
    } catch (e) {
      alert('Something is wrong! Please check your configs!')
    }
  },
  fetchSelectedAppLogs(ctx, payload) {
    const sitesRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(payload.id).collection('logs')
    return sitesRef.onSnapshot(querySnapshot => {
      const logs = []
      querySnapshot.forEach(doc => {
        const message = {
          title: doc.data().title,
          time: doc.data().time,
          message: doc.data().message,
          buildId: doc.data().buildId,
          variant: doc.data().variant,
        }
        logs.push(message)
        logs.sort((a, b) => b.time - a.time)
      })
      ctx.commit('setSelectedAppLogs', logs)
    })
  },
  deleteApp(ctx, payload) {
    ctx.dispatch('loading/startLoading', null, { root: true })
    const sitesRef = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('sites').doc(payload).delete()
      .then(() => {
        ctx.dispatch('loading/stopLoading', null, { root: true })
        router.push({ name: 'home' })
        ctx.dispatch('toaster/setMessage', {
          title: 'App Deleted Successfully',
          icon: 'DeleteIcon',
          variant: 'success',
        }, { root: true })
      })
      .catch(error => {
        ctx.dispatch('loading/stopLoading', null, { root: true })
      })
  },
}
