import * as fb from '../../../firebase'
// ctx.rootGetters['auth/userId'])
export default {
  fetchAlluserPurchases(ctx) {
    const sitesRef = fb.ordersCollection.where('user_id', '==', ctx.rootGetters['auth/userId'])
    ctx.commit('setPurchases', [])
    ctx.dispatch('loading/startLoading', null, { root: true })
    return sitesRef.get().then(querySnapshot => {
      const userPurchases = []
      querySnapshot.forEach(doc => {
        const puchase = {
          date: doc.data().date,
          discount: doc.data()?.discount,
          products: doc.data()?.products,
          method: doc.data().method,
          order_id: doc.data().order_id,
          ref: doc.data().ref,
          siteName: doc.data().siteName,
          status: doc.data().status,
          subtotal: doc.data().subtotal,
          total: doc.data().total,
        }

        userPurchases.unshift(puchase)
      })
      ctx.commit('setPurchases', userPurchases)
      ctx.dispatch('loading/stopLoading', null, { root: true })
    })
  },
}
