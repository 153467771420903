import myFile from '../assets/configs/features.json'

/**
* @param {string} key - Feature Key.
* @param {any} requestedValue - Value Requested for build.
* @param {string} buildType - basic || satandard || premium.
* @returns Requested Value or false.
*/
const getAllowedFeatureValue = (key, requestedValue, buildType) => {
  const isAllowed = myFile[key][buildType]
  if (isAllowed) {
    return requestedValue
  }
  return false
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getAllowedFeatureValue,
}
