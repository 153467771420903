import * as objectHash from 'object-hash'
import firebase from 'firebase'
import * as fb from '../../firebase'

export default {
  namespaced: true,
  state() {
    return {
      totalDetails: {
        total: 0,
        discount: 0,
        tax: 0,
        subTotal: 0,
      },
    }
  },
  getters: {
    currentTotalDetails: state => state.totalDetails,
  },
  mutations: {
    UPDATE_TOTALS(state, val) {
      state.totalDetails = val
    },
    setSellingProduct(state, product) {
      const cartArray = JSON.parse(localStorage.getItem('cart')) || []
      let isAvailable = false
      for (let i = 0; i < cartArray.length; i += 1) {
        if (cartArray[i].title === product.title) {
          isAvailable = true
        }
      }
      if (!isAvailable) {
        cartArray.push(product)
      }
      localStorage.setItem('cart', JSON.stringify(cartArray))
    },
    removeSellingProduct(state, product) {
      const cartArray = JSON.parse(localStorage.getItem('cart')) || []
      for (let i = 0; i < cartArray.length; i += 1) {
        if (cartArray[i].title === product.title) {
          cartArray.splice(i, 1)
        }
      }
      localStorage.setItem('cart', JSON.stringify(cartArray))
    },
    updateSellingProductQty(state, product) {
      const cartArray = JSON.parse(localStorage.getItem('cart')) || []
      for (let i = 0; i < cartArray.length; i += 1) {
        if (cartArray[i].title === product.title) {
          cartArray[i].qty = product.qty
        }
      }
      localStorage.setItem('cart', JSON.stringify(cartArray))
    },
    clearAllSellingProducts() {
      localStorage.setItem('cart', JSON.stringify([]))
    },
    setPaymentSignature(state, signature) {
      localStorage.setItem('session', objectHash.sha1(signature))
    },
    clearPaymentSignature() {
      localStorage.setItem('session', '')
    },
  },
  actions: {
    setTotals(context, payload) {
      context.commit('UPDATE_TOTALS', payload)
    },
    setSellingProduct(ctx, product) {
      ctx.commit('setSellingProduct', product)
    },
    getSellingProduct() {
      return JSON.parse(localStorage.getItem('cart')) || []
    },
    removeSellingProduct(ctx, product) {
      ctx.commit('removeSellingProduct', product)
    },
    updateSellingProductQty(ctx, product) {
      ctx.commit('updateSellingProductQty', product)
    },
    clearAllSellingProducts(ctx) {
      ctx.commit('clearAllSellingProducts')
    },
    setPaymentSignature(context, payload) {
      context.commit('setPaymentSignature', payload)
    },
    validatePaymentSignature(ctx, signature) {
      return objectHash.sha1(signature) === localStorage.getItem('session')
    },
    clearPaymentSignature(ctx) {
      ctx.commit('clearPaymentSignature')
    },
    async getNewOrderId() {
      const orders = fb.ordersCollection.doc()
      const newDocRef = await orders.get()
      return newDocRef.id
    },
    async createNewOrder(ctx, payload) {
      console.log(payload)
      const orders = fb.ordersCollection.doc()
      const newDocRef = await orders.get()
      const id = payload.id ? payload.id : newDocRef.id
      fb.ordersCollection.doc(id).set({
        user_id: ctx.rootGetters['auth/userId'],
        order_id: id,
        date: new Date(),
        subtotal: payload.subtotal,
        discount: payload.discount,
        total: payload.total,
        status: payload.status,
        method: payload.method,
        products: payload.products,
        ref: payload.ref,
      })
        .then(response => {
          console.log(response)
          ctx.dispatch('updateUserOrders', {
            order_id: id, subtotal: payload.subtotal, total: payload.total, discount: payload.discount, products: payload.products, method: payload.method, status: payload.status, ref: payload.ref,
          })
        })
    },
    async updateUserOrders(ctx, payload) {
      const userOrder = fb.usersCollection.doc(ctx.rootGetters['auth/userId']).collection('orders').doc()
      userOrder.set({
        id: payload.order_id,
        date: new Date(),
        subtotal: payload.subtotal,
        discount: payload.discount,
        total: payload.total,
        status: payload.status,
        method: payload.method,
        products: payload.products,
        ref: payload.ref,
      }).then(res => { console.log(res) })
    },
    async updateOrderStatus(ctx, payload) {
      const order = fb.ordersCollection.doc(payload.order_id)
      order.set({
        status: payload.status,
      })
        .then(response => {
          console.log(response)
        })
    },
    async updateUserBuilds(ctx, payload) {
      const basicBuildsToAdd = 3 * payload.basic
      const standardBuildsToAdd = 3 * payload.standard
      const premiumBuildsToAdd = 3 * payload.premium
      let basicSubscriptionEnd = new Date()
      let standardSubscriptionEnd = new Date()
      let premiumSubscriptionEnd = new Date()
      // Check and update basic subscription end date
      if (ctx.rootGetters['auth/user'].basicSubscriptionEndDate && ctx.rootGetters['auth/user'].basicSubscriptionEndDate.toDate) {
        basicSubscriptionEnd = new Date(ctx.rootGetters['auth/user'].basicSubscriptionEndDate.toDate())
      }
      basicSubscriptionEnd.setDate(basicSubscriptionEnd.getDate() + (30 * payload.basicSubscription))

      // Check and update standard subscription end date
      if (ctx.rootGetters['auth/user'].standardSubscriptionEndDate && ctx.rootGetters['auth/user'].standardSubscriptionEndDate.toDate) {
        standardSubscriptionEnd = new Date(ctx.rootGetters['auth/user'].standardSubscriptionEndDate.toDate())
      }
      standardSubscriptionEnd.setDate(standardSubscriptionEnd.getDate() + (30 * payload.standardSubscription))

      // Check and update premium subscription end date
      if (ctx.rootGetters['auth/user'].premiumSubscriptionEndDate && ctx.rootGetters['auth/user'].premiumSubscriptionEndDate.toDate) {
        premiumSubscriptionEnd = new Date(ctx.rootGetters['auth/user'].premiumSubscriptionEndDate.toDate())
      }
      premiumSubscriptionEnd.setDate(premiumSubscriptionEnd.getDate() + (30 * payload.premiumSubscription))
      const user = fb.usersCollection.doc(ctx.rootGetters['auth/userId'])
      user.set({
        remainingBasicBuilds: firebase.firestore.FieldValue.increment(basicBuildsToAdd),
        remainingStandardBuilds: firebase.firestore.FieldValue.increment(standardBuildsToAdd),
        remainingPremiumBuilds: firebase.firestore.FieldValue.increment(premiumBuildsToAdd),
        basicSubscriptionEndDate: basicSubscriptionEnd,
        standardSubscriptionEndDate: standardSubscriptionEnd,
        premiumSubscriptionEndDate: premiumSubscriptionEnd,
      }, { merge: true }).then(() => {
        ctx.dispatch('auth/fetchUserProfileWithoutNav', ctx.rootGetters['auth/userId'], { root: true })
      })
    },
  },
}
