import * as fb from '../../../firebase'
// eslint-disable-next-line import/no-cycle
import router from '../../../router/index'

export default {
  login(context, payload) {
    return fb
      .auth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then(response => {
        // @TODO mutate user state.
        context.dispatch('fetchUserProfile', response.user.uid)
      })
  },
  signup({ dispatch }, payload) {
    return fb
      .auth
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(response => {
      // @TODO mutate user state.
        dispatch('createUser', {
          userId: response.user.uid,
          userName: payload.username,
          email: payload.email,
        })
        router.push('login')
      })
  },
  createUser(_, payload) {
    fb.usersCollection.doc(payload.userId).set({
      remainingBasicBuilds: 3,
      remainingPremiumBuilds: 0,
      remainingStandardBuilds: 0,
      usedBasicBuilds: 0,
      usedPremiumBuilds: 0,
      userId: payload.userId,
      userName: payload.userName,
      email: payload.email,
    }).then(docRef => {
      console.log('Document written with ID: ', docRef.id)
    })
      .catch(error => {
        console.error('Error adding document: ', error)
      })
  },
  resetPassword(_, payload) {
    return fb
      .auth
      .sendPasswordResetEmail(payload.email)
      .then(response => {
      // @TODO mutate user state.
        console.log(response)
        // router.push('home')
      })
  },
  fetchUserProfile(context, payload) {
    let userProfile
    // fetch user profile
    fb.usersCollection.doc(payload).get().then(doc => {
      if (doc.exists) {
        userProfile = doc.data()
        context.dispatch('setuserData', userProfile)
        setTimeout(() => {
          router.push('dashboard')
        }, 0)
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    })
  },
  fetchUserProfileWithoutNav(context, payload) {
    let userProfile
    // fetch user profile
    fb.usersCollection.doc(payload).get().then(doc => {
      if (doc.exists) {
        userProfile = doc.data()
        context.dispatch('setuserData', userProfile)
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    })
  },
  setuserData(context, payload) {
    context.commit('setUser', payload)
  },
  setisAuth(context, payload) {
    context.commit('setAuthentication', payload)
  },
  autologin(context) {
    let userProfile
    const user = fb.auth.currentUser
    if (user) {
      fb.usersCollection.doc(user.uid).get().then(doc => {
        if (doc.exists) {
          userProfile = doc.data()
          context.dispatch('setuserData', userProfile)
        } else {
          console.log('No such document!')
        }
      })
    }
  },
  logout(context) {
    return fb.auth.signOut().then(() => {
      context.commit('appSites/removeUserApps', null, { root: true })
      context.commit('removeUser')
      router.push('/')
      window.location.reload()
    })
  },
}
