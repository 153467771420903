import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import UUID from 'vue-uuid'
import { auth } from './firebase'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.directive('ripple', Ripple)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(UUID)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/assets/fonts/feather/iconfont.css')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})
let app
auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch('auth/setisAuth', true)
  } else {
    store.dispatch('auth/setisAuth', false)
  }
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})

Vue.prototype.moment = moment

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
  return null
})
Vue.filter('formatDateWithoutTime', value => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
  return null
})
