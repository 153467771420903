/* eslint-disable import/no-cycle */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      userId: '',
      email: '',
      userName: '',
      isAuthenticated: false,
      remainingBasicBuilds: 0,
      remainingPremiumBuilds: 0,
      remainingStandardBuilds: 0,
      usedBasicBuilds: 0,
      usedPremiumBuilds: 0,
      basicSubscriptionEndDate: new Date(),
      standardSubscriptionEndDate: new Date(),
      premiumSubscriptionEndDate: new Date(),
    }
  },
  mutations,
  actions,
  getters,
}
