import * as fb from '../../../firebase'

export default {
  isAuthenticated() {
    return !!fb.auth.currentUser
  },
  user(state) {
    return state
  },
  selectedApp(state) {
    return state.selectedApp
  },
  userApps(state) {
    return state.userApps
  },
  selectedAppLogs(state) {
    return state.selectedAppLogs
  },
}
