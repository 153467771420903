export default {
  namespaced: true,
  state: {
    isLoading: false,
  },
  actions: {
    startLoading(context) {
      context.commit('startLoading')
    },
    stopLoading(context) {
      context.commit('stopLoading')
    },
  },
  mutations: {
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
  },
  getters: {
    loadingStatus(state) {
      return {
        isLoading: state.isLoading,
      }
    },
  },
}
