<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    myMessage() {
      return this.$store.getters['toaster/myMessage']
    },
  },
  watch: {
    myMessage(payload) {
      if (payload.title !== null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: payload.title,
            icon: payload.icon,
            variant: payload.variant,
          },
        })
        this.$store.dispatch('toaster/clearMessage')
      } else {
        // this.$toast.destroy() // not sure if it works, but I assume that might close open toasts
      }
    },
  },
  created() {
    this.$store.dispatch('auth/autologin')
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  metaInfo: {
    // Children can override the title.
    title: 'Home',
    titleTemplate: '%s | Web To App Convert',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Convert any website to Android Application for free.' },
      { name: 'keywords', content: 'Web To App, Convert Web To Android, Android, Website to App, Wordpress to Android, App, Free App Maker, Free, Convert, Website To app' },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Home | Web To App Convert' },
      { property: 'og:site_name', content: 'Web To App Convert' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.webtoappconvert.com' },
      { property: 'og:image', content: 'https://www.webtoappconvert.com/img/logo.png' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Convert any website to Android Application for free.' },
      // Twitter card
      { name: 'twitter:card', content: 'Convert any website to Android Application for free.' },
      { name: 'twitter:site', content: 'https://www.webtoappconvert.com' },
      { name: 'twitter:title', content: 'Home | Web To App Convert' },
      { name: 'twitter:description', content: 'Convert any website to Android Application for free.' },
      // Your twitter handle, if you have one.
      // { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: 'https://www.webtoappconvert.com/img/logo.png' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Home | Web To App Convert' },
      { itemprop: 'description', content: 'Convert any website to Android Application for free.' },
      { itemprop: 'image', content: 'https://www.webtoappconvert.com/img/logo.png' },
    ],
  },
}
</script>
