/* eslint-disable import/no-cycle */
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state() {
    return {
      selectedApp: null,
      userApps: null,
      selectedAppLogs: null,
    }
  },
  mutations,
  actions,
  getters,
}
