import * as fb from '../../../firebase'

export default {
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  user(state) {
    return state
  },
  userId() {
    return fb.auth.currentUser.uid
  },
}
